import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Divider, Button, Input, Modal, Tabs, Select, Drawer, message, Checkbox, Form, Spin, Table } from "antd";
import { SearchOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CloseOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../../lib/printer/htmlPrinter";
import Close from "../../../../assets/images/close-x.svg";
import User from "../../../../assets/images/User.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import db from "../../../../database";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import userTieIcon from "../../../../assets/images/userTieIcon.svg";

const PointOfSuperMarketModals = (props) => {
  const {
    cart,
    tillData,
    setCart,
    openSalesRep,
    setOpenSalesRep,
    selectedProductInCart,
    isStockModal,
    setIsStockModal,
    addProduct,
    handleCartTotalClick,
    tempCart,
    setTempCart,
    deleteCart,
    deleteProduct,
  } = props;
  const { t } = useTranslation();
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [salesRepData, setSalesRepData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState({});
  const [itemWeights, setItemWeights] = useState(() => {
    // Initialize with the cart items' weights
    const initialWeights = {};
    cart.items.forEach((item) => {
      initialWeights[item.productId] = item.availableQty; // Store as string to allow flexible input
    });
    return initialWeights;
  });

  const [searchTerm, setSearchTerm] = useState("");
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};

  useEffect(async () => {
    await db.salesRep.toArray((res) => {
      setSalesRepData(res);
    });
  }, []);

  useEffect(() => {
    const initialWeights = {};
    cart.items.forEach((item) => {
      initialWeights[item.productId] = item?.availableQty?.toString() || 0; // Store as string to allow flexible input
    });
    setItemWeights(initialWeights);
  }, [cart]);

  const renderThumbHorizontalScroll = ({ style, ...props }) => {
    const thumbStyle = {
      // backgroundColor: "#c1c1c1",
      // borderRadius: "5px",
      width: "0px",
    };
    // setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const fetchSalesRepData = async (searchTerm) => {
    console.log("searchTerm", searchTerm);
    const results = await db.salesRep.filter((res) => res.name.toLowerCase().includes(searchTerm.toLowerCase()) || res.code.includes(searchTerm)).toArray();
    console.log("Fetched Sales Reps:", results);
    return results;
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    console.log("value", value);
    setSearchTerm(value);
    const data = await fetchSalesRepData(value);
    setSalesRepData(data);
  };

  // Handle input changes and update the temporary cart
  const handleQtyChange = (value, recordIndex, productId) => {
    const updatedWeight = parseFloat(value);
    const updatedTempCart = { ...tempCart };
    let tempData = { ...itemWeights };
    tempData[productId] = isNaN(updatedWeight) ? 0 : updatedWeight;
    setItemWeights(tempData);
    setTempCart(updatedTempCart);
  };

  // On submit, update the actual cart and save to localStorage
  const handleSubmit = async () => {
    let showPaymentsFlag = false;
    let deleteItems = [];
    await Promise.all(
      tempCart.items.map((item) => {
        if (itemWeights[item.productId] > 0) {
          showPaymentsFlag = true;
          return addProduct(item, itemWeights[item.productId], true); // Assuming addProduct is async, return the promise
        } else {
          deleteItems.push(item);
        }
      })
    );
    if (tempCart.items.length !== deleteItems.length) {
      await Promise.all(
        deleteItems.map((item) => {
          deleteProduct(item);
        })
      );
    } else {
      deleteCart();
    }
    if (showPaymentsFlag) {
      handleCartTotalClick();
    }
  };

  return (
    <Fragment>
      {/* sales rep modal start */}
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Select a Sales Representative</p>
            <img
              src={Close}
              onClick={() => {
                setSelectedSalesRep({});
                setOpenSalesRep({ flag: false, level: "" });
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={openSalesRep.flag}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => {
          setSelectedSalesRep({});
          setOpenSalesRep({ flag: false, level: "" });
        }}
        //  id="sm-drawer-close"
        height="90vh"
      >
        <Row style={{ paddingLeft: "0.7vw", fontSize: "1vw", paddingBottom: "2vh" }}>
          <Col span={9}>
            <Input
              type="text"
              className="productSearch"
              placeholder={`Search`}
              id="giftCardSearchInput"
              //   onPressEnter={() => (filteredGiftCards.length === 1 ? selectGiftCard(filteredGiftCards[0]) : null)}
              //   value={searchTerm}
              onChange={handleSearchChange}
              onPressEnter={(e) => e.preventDefault()}
              style={{
                width: "35vw",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "1vw",
                color: "#0F0718",
                borderColor: "rgba(0, 0, 0, 0.25)",
              }}
              prefix={
                <img
                  src={SearchIcon}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingLeft: "0.2vw",
                  }}
                />
              }
            />
          </Col>
        </Row>
        <Scrollbars style={{ height: "55vh", width: "100%" }} renderThumbHorizontal={renderThumbHorizontalScroll}>
          <Row gutter={[16, 16]}>
            {salesRepData?.map((res) => (
              <Col
                span={6}
                style={{
                  background: selectedSalesRep.code === res.code ? "#cae5bd" : "#fff",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 1rem",
                  padding: "0rem 0.5rem",
                }}
                onClick={() => {
                  setSelectedSalesRep(res);
                }}
              >
                <img src={User} alt="" style={{ marginRight: "10px", height: "2.5rem", margin: "0.7rem 0 " }} />
                <span style={{ marginLeft: "10px", margin: "0.7rem 0px 0.7rem 10px", fontSize: "1vw" }}>
                  <p style={{ margin: 0, fontWeight: 500, fontSize: "1em", color: "#0F0718" }}>{res.name}</p>
                  <span style={{ fontSize: "0.8em", fontWeight: 500, color: "#0F0718" }}>{res.code}</span>
                </span>
              </Col>
            ))}
          </Row>
        </Scrollbars>
        <Row>
          <Col span={24} style={{ textAlign: "right", paddingTop: "1rem" }}>
            <Button
              style={{ backgroundColor: "#0F0718", color: "#fff", height: "7vh", width: "15vw", borderRadius: "7px" }}
              disabled={!selectedSalesRep?.code}
              onClick={() => {
                let cartObj = cart;
                if (openSalesRep.level === "order") {
                  cartObj.salesRepId = selectedSalesRep.s_salesrep_id;
                  cartObj.salesRepName = selectedSalesRep.name;

                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                } else if (openSalesRep.level === "line") {
                  cartObj?.items?.map((lineItem) => {
                    if (lineItem.productId === selectedProductInCart.productId && lineItem.lineId === selectedProductInCart.lineId) {
                      lineItem.salesRepId = selectedSalesRep.s_salesrep_id;
                      lineItem.salesRepName = selectedSalesRep.name;
                    }
                  });
                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                }
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Drawer>
      {/* sales rep modal end */}

      {/* Stock Confirmation Start */}
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Stock Confirmation</p>
            <img
              src={Close}
              onClick={() => {
                setIsStockModal(false);
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={isStockModal}
        className="filter"
        bodyStyle={{ padding: "0 4vw" }}
        onClose={() => {
          setIsStockModal(false);
        }}
        //  id="sm-drawer-close"
        height="90vh"
      >
        <Table
          className="sm-cart-table-pos-stock"
          style={{
            width: "100%",
            overflowY: "auto",
            border: "none",
            borderRadius: "7px",
            padding: "0px 7px",
            height: "67vh",
            background: themeJSON.productTableBGColor || "#fff",
          }}
          pagination={false}
          dataSource={
            // Filter the data where weight is greater than 0
            (parseFloat(localStorage.getItem("cartObj"))?.items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items || []).filter((item) => item.weight !== 0)
          }
          sticky
          id="sm-cart-table"
          columns={[
            {
              title: "Item Name",
              dataIndex: "name",
              key: "name",
              width: "32%",
              render: (text, record, index) => {
                return (
                  <>
                    <div onClick={() => {}} id="sm-product-name" className="tableRow">
                      {record.name}
                    </div>
                    <div className="offerName">
                      {record.availableQty >= record.weight ? (
                        <p style={{ margin: 0, color: "#009000" }}>Available</p>
                      ) : record.availableQty > 0 && record.weight > 0 ? (
                        <p style={{ margin: 0, color: "#FF5E0E" }}>Only {record.availableQty} Available</p>
                      ) : (
                        <p style={{ margin: 0, color: "red" }}>Out Of Stock</p>
                      )}
                    </div>
                  </>
                );
              },
            },
            {
              title: "UOM",
              dataIndex: "uom_name",
              key: "uom_name",
              width: "12%",
              render: (text, record, index) => {
                return <div style={{ textAlign: "left" }}>{text}</div>;
              },
              align: "center",
            },
            {
              title: "Order Qty",
              dataIndex: "weight",
              key: "weight",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return <div style={{ textAlign: "right" }}>{parseFloat(text).toFixed(2)}</div>;
              },
            },
            {
              title: "Available Qty",
              dataIndex: "availableQty",
              key: "availableQty",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return (
                  <div style={{ textAlign: "right", color: text > 0 ? "#0F0718" : "red" }}>
                    {text?.toFixed(2).length < 8 ? parseFloat(text)?.toFixed(2) : `${text?.toString().substring(0, 8)}`}
                  </div>
                );
              },
            },
            {
              title: "Update Order Qty",
              dataIndex: "availableQty",
              key: "availableQty",
              width: "22%",
              align: "center",
              paddingLeft: "3vw",
              textAlign: "right",
              render: (text, record, index) => {
                return (
                  <Input
                    style={{ width: "10vw", textAlign: "right" }}
                    value={itemWeights[record.productId]} // Use local state to control the value
                    onChange={(e) => handleQtyChange(e.target.value, index, record.productId)} // Handle input change
                  />
                );
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#2F3856",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
            onClick={() => {
              setIsStockModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={() => {
              console.log("Finalized Order");
              handleSubmit();
              setIsStockModal(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
      {/* Stock Confirmation End */}
    </Fragment>
  );
};

export default PointOfSuperMarketModals;
