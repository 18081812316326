import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography, Modal, Radio } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import defaultImage from "../../../assets/images/no-image.svg";
import Back from "../../../assets/images/backIcon.svg";
import data from "../../../constants/restaurantPos.json";
import removeBg from "../../../assets/images/removebg.png";
import PlusIcon from "../../../assets/images/plusIconRestaurant.svg";
import minusIcon from "../../../assets/images/minusIconRestaurant.svg";
import DecrementImg from "../../../assets/images/Decrement2.svg";
import IncrementImg from "../../../assets/images/Increment2.svg";
import userIcon from "../../../assets/images/userId.svg";
import { v4 as uuidv4 } from "uuid";
import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";
import _ from "lodash";

const MenuLayout = ({
  cart,
  restaurantProductCategory,
  productsData,
  pickProduct,
  decreaseProductQty,
  deleteProduct,
  increaseProductQty,
  handleOrder,
  handleVoid,
  addDefinedProductWithAddons,
  addProductWithAddons,
  setKeyValue,
  setPaymentVisible,
  userPreferences,
  setDisplayCustomerSearch,
  categorieFlag,
  setCategorieFlag
}) => {
  const { Text, Title } = Typography;
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  
  const [categorieName, setCategorieName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [selectedProduct, setSelectedProduct] = useState(null);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  // Add ONS Modal Starts //

  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleAddOnModal = (record, event) => {
    event.stopPropagation();
    console.log("Record:", record);
    setDisplayAddOnSelection(true);
    setSelectedProduct(record);

    const requiredListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 1);
    const optionsListFilter = _.filter(record.productAddons, (item) => item.mAddonGroup.minqty === 0);
    setAddOnsList({
      requiredList: requiredListFilter,
      optionsList: optionsListFilter,
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }

    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  const handleAdd = async () => {
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        productAddons: selectedAddons || [],
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    handleAddOnModalClose();
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  useEffect(() => {
    db.productCategories
      .toArray()
      .then((response) => {
        // console.log("Product Categories Data:", cart);
        setProductCategoriesList(response);
      })
      .catch((error) => {
        // Handle any errors that occur
        console.error(error);
      });
  }, []);

  const showProductModal = (product) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedProduct(null);
  };

  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <div style={{ width: cart.items.length > 0 ? "69%" : "100%", height: "100vh" }}>
        <Row style={{ padding: "2vh 1.5vw 0 1.5vw", fontSize: "1vw" }}>
          <Col span={24}>
            <p style={{ fontWeight: 500, paddingLeft: "3px", fontSize: "1.25em", color: "#0F0718", paddingTop: "0.5em" }}>
              {categorieFlag ? <img src={Back} style={{ height: "4.5vh", cursor: "pointer" }} onClick={() => setCategorieFlag(false)} /> : null} Order: {cart.documentno}
            </p>
          </Col>
          <Col span={17}>
            <Input
              placeholder="Search for products by code/name"
              id="sm-product-search"
              onChange={(e) => {
                // e.target.value === "" ? clearProductSearchResults() : onChangeInput(e);
              }}
              className="productSearch-sm"
              style={{ borderRadius: "20px", padding: "0.8vh 0.8vw", borderColor: "#fff" }}
              // value={productSearchInput}
              // suffix={
              //   <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              //     {productSearchInput.length > 0 ? (
              //       <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
              //     ) : null}
              //   </div>
              // }
              prefix={
                <img
                  src={SearchIcon}
                  alt=""
                  onClick={() => {
                    // onBarcodeInput(productSearchInput, false);
                  }}
                />
              }
            />
          </Col>
          <Col span={24} style={{ paddingTop: "2vh" }}>
            <span style={{ fontWeight: 500, paddingLeft: "3px", paddingBottom: "0", fontSize: "1.5em", color: "#0F0718" }}>
              {categorieFlag ? categorieName : "Select By Categories"}
            </span>
          </Col>
        </Row>
        <div className="tables-container-category">
          <Scrollbars autoHide style={{ height: "76vh" }}>
            <InfiniteScroll
              dataLength={productCategoriesList.length}
              hasMore={true}
              endMessage={
                <p className="center">
                  <b>Yay! you have seen it all</b>
                </p>
              }
              style={{ overflowX: "hidden" }}
            >
              <Row gutter={[16, 16]}>
                {!categorieFlag
                  ? productCategoriesList.map((categorie) => (
                      <Col span={cart.items.length > 0 ? 6 : 4} onClick={() => {}}>
                        <Card
                          onClick={() => {
                            setCategorieName(categorie.name);
                            restaurantProductCategory(categorie.mProductCategoryId);
                            setCategorieFlag(true);
                          }}
                          style={{
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%), 
                            url(${categorie?.imageurl ? categorie?.imageurl?.trim() : defaultImage}) lightgray 50% / cover no-repeat`,
                            backgroundColor: "#FFF",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "24vh",
                            width: "14vw",
                            position: "relative",
                            borderRadius: "10px",
                            overflow: "hidden",
                            cursor: "pointer",
                            fontSize: "1vw",
                          }}
                        >
                          <span
                            style={{
                              textAlign: "center",
                              padding: "4px 8px",
                              color: "#FFFFFF",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "1.25em",
                              fontWeight: "500",
                            }}
                          >
                            {categorie.name}
                          </span>
                        </Card>
                      </Col>
                    ))
                  : productsData.map((product) => (
                      <Col span={cart.items.length > 0 ? 6 : 4} key={product.id} onClick={() => {}}>
                        <Card
                          onClick={product?.productAddons?.length > 0 ? (e) => handleAddOnModal(product, e) : () => pickProduct(product)}
                          // onClick={product?.productAddons?.length > 0 ? (e) => handleAddOnModal(product, e) : () => pickProduct(product)}
                          // onClick={product?.productAddons?.length > 0 ? (e) => showProductModal(product) : () => pickProduct(product)}
                          style={{ height: "28vh", borderRadius: "5px", cursor: "pointer" }}
                          // /onClick={(e) => handleAddOnModal(product, e)}
                        >
                          <img
                            src={product?.imageurl?.trim()}
                            style={{
                              height: "17vh",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              borderRadius: "10px",
                              padding: "0.8vh 0.55vw",
                              width: "100%",
                            }}
                          />
                          <div style={{ height: "7vh", paddingLeft: "0.8vw", fontWeight: 500, fontSize: "1vw", paddingRight: "0.8vw" }}>
                            <p style={{ margin: "0", fontSize: "1em", fontWeight: 500, color: "#0F0718", height: "6vh" }}>{product.name}</p>
                            <Row>
                              <Col span={21} style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>
                                {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                                {product.sunitprice}
                                {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                              </Col>
                              <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "2px" }}>
                                <img src={removeBg} style={{ height: "1.8vh" }} />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    ))}
              </Row>
            </InfiniteScroll>
          </Scrollbars>
        </div>
      </div>
      {cart.items.length > 0 ? (
        <div style={{ background: "#fff", height: "100vh", width: "31%", position: "relative", fontSize: "1vw" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "2vh 1vw 0 1vw" }}>
            <span style={{ fontWeight: 600, fontSize: "1.5em", color: "#0F0718" }}>Order Details</span>
            <span style={{ fontWeight: 500, fontSize: "1em", color: "#929098" }}>{moment(cart.orderTime).format(userPreferences.dateFormat)}</span>
          </div>
          <p style={{ padding: "0 1vw", fontSize: "1.25em", color: "#0F0718", marginBottom: 0 }}>{cart.tableNo}</p>
          {tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId === cart.customer.cwrCustomerId ? (
            <button
              style={{
                marginLeft: "1.2vw",
                color: "#0F0718",
                backgroundColor: "#fff",
                border: "1px solid #92909880",
                fontSize: "0.85em",
                borderRadius: "4px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.2vh 0.5vw",
                cursor: "pointer",
              }}
              onClick={() => setDisplayCustomerSearch(true)}
            >
              <img src={userIcon} style={{ height: 15, width: "1.5vw", marginRight: "0.2vw" }} />
              Add Customer
            </button>
          ) : (
            <button
              style={{
                marginLeft: "1.2vw",
                color: "#0F0718",
                backgroundColor: "#fff",
                border: "1px solid #92909880",
                fontSize: "0.85em",
                borderRadius: "4px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.2vh 0.5vw",
                cursor: "pointer",
              }}
            >
              <img src={userIcon} style={{ height: 15, width: "1.5vw", marginRight: "0.2vw" }} />
              {cart.customer.name}
            </button>
          )}
          <hr style={{ color: "#929098", width: "93%" }} />
          <Scrollbars autoHide style={{ height: "58vh" }}>
            <InfiniteScroll
              dataLength={cart.items.length}
              hasMore={true}
              endMessage={
                <p className="center">
                  <b>Yay! you have seen it all</b>
                </p>
              }
              style={{ overflowX: "hidden" }}
            >
              {cart.items.map((item) => {
                return (
                  <div style={{ padding: "5px 12px 12px 12px", fontSize: "1vw" }}>
                    <Row>
                      <Col span={5}>
                        <img src={item.imageurl} alt={item.name} style={{ height: "8.5vh", width: "5.5vw", objectFit: "cover", borderRadius: "7px" }} />
                      </Col>
                      <Col span={18} style={{ display: "flex", flexDirection: "column", paddingLeft: "0.5vw", position: "relative" }}>
                        <Text style={{ fontSize: "1em", fontWeight: "500", color: "#0F0718" }}>{item.name}</Text>
                        <Text style={{ fontSize: "0.8em", color: "#929098", fontWeight: 400 }}>{item.description}</Text>
                        <Text style={{ fontWeight: 500, fontSize: "1.25em", color: "#0F0718", position: "absolute", bottom: 0 }}>
                          {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                          {item.salePrice}
                          {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                        </Text>
                        <Space style={{ paddingLeft: "14vw", position: "absolute", bottom: 0 }}>
                          <img
                            src={minusIcon}
                            height="25"
                            width="25"
                            onClick={() => (parseFloat(item.weight) > 1 ? decreaseProductQty(item) : deleteProduct(item))}
                            style={{ cursor: "pointer" }}
                          />
                          <Text style={{ margin: "0 8px" }}>{item.weight}</Text>
                          <img src={PlusIcon} height="25" width="25" onClick={() => increaseProductQty(item)} style={{ cursor: "pointer" }} />
                        </Space>
                      </Col>
                      {/* <Col span={7} style={{ display: "flex", alignItems: "flex-end" }}>
                        <Space>
                          <img
                            src={minusIcon}
                            height="25"
                            width="25"
                            onClick={() => (parseFloat(item.weight) > 1 ? decreaseProductQty(item) : deleteProduct(item))}
                            style={{ cursor: "pointer" }}
                          />
                          <Text style={{ margin: "0 8px" }}>{item.weight}</Text>
                          <img src={PlusIcon} height="25" width="25" onClick={() => increaseProductQty(item)} style={{ cursor: "pointer" }} />
                        </Space>
                      </Col> */}
                    </Row>
                  </div>
                );
              })}
            </InfiniteScroll>
          </Scrollbars>
          <div style={{ padding: "1vh 0.6vw", position: "absolute", bottom: "1vh", fontSize: "1vw" }}>
            <Space direction="vertical" style={{ width: "100%", background: "#F3F4F9", padding: "1vh 0.5vw", borderRadius: "10px", rowGap: "4px" }}>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>Subtotal</Text>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                  {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                  {(
                    parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.total : cart.total).toFixed(2) -
                    parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.tax : cart.tax).toFixed(2)
                  ).toFixed(2)}
                  {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>Discount Sales</Text>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                  {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                  {parseFloat(cart.discount).toFixed(2)}
                  {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>Total Sales Tax</Text>
                <Text style={{ fontSize: "1em", fontWeight: 500, color: "#929098", fontFamily: "Inter" }}>
                  {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                  {parseFloat(cart.tax).toFixed(2)}
                  {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between", marginTop: "2px" }}>
                <Text style={{ fontSize: "1.1em", color: "#0F0718", fontWeight: 600 }}>Total Amount</Text>
                <Text style={{ fontSize: "1.1em", color: "#0F0718", fontWeight: 600 }}>
                  {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                  {parseFloat(cart.total).toFixed(2)}
                  {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                </Text>
              </Space>
            </Space>
            <div>
              <Space direction="horizontal" style={{ paddingTop: "2vh", fontSize: "1vw" }}>
                <Button
                  onClick={() => handleVoid()}
                  style={{ width: "7vw", border: "1px solid rgba(146, 144, 152, 0.5)", borderRadius: "10px", color: "#2F3856", height: "6vh", fontSize: "1em", fontWeight: "500" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (localStorage.getItem("dineIn") === "N") {
                      setPaymentVisible(true);
                      setKeyValue("3");
                    } else {
                      handleOrder();
                    }
                  }}
                  style={{
                    width: "19.5vw",
                    borderRadius: "10px",
                    backgroundColor: "#2F3856",
                    color: "#fff",
                    height: "6vh",
                    fontSize: "1em",
                    fontWeight: "500",
                  }}
                >
                  {localStorage.getItem("dineIn") === "N" ? "Pay Bill" : "Send To Kitchen"}
                </Button>
              </Space>
            </div>
          </div>
        </div>
      ) : null}

      {/* Modal for product details */}

      {/* modal for add on's starts */}

      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 13, background: "#fff" }}
        visible={displayAddOnSelection}
        onCancel={handleAddOnModalClose}
        // width={
        //   (addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 0) ||
        //   (addOnsList?.requiredList?.length === 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1)
        //     ? "35%"
        //     : addOnsList?.requiredList?.length > 0 && Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name"))?.length === 1
        //     ? "48%"
        //     : "60%"
        // }
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          margin: 0,
        }}
        width={"28%"}
      >
        <Row style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Col span={24} style={{ paddingBottom: 0, fontSize: "1vw" }}>
            <img src={selectedProduct?.imageurl} alt={selectedProduct?.name} style={{ width: "100%", borderRadius: "5px", height: "25vh", display: "block" }} />

            <div style={{ padding: "10px 5px 0 5px", fontSize: "1vw" }}>
              <img src={removeBg} style={{ height: "1.9vh", marginBottom: "10px", marginTop: "0.6em" }} />

              <span style={{ fontSize: "1.2em", fontWeight: "500", color: "#0F0718", paddingLeft: "0.7vw" }}>{selectedProduct?.name}</span>
              <span style={{ fontSize: "1.2em", fontWeight: "600", color: "#0F0718", paddingLeft: "2.7vw" }}>
                {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                {selectedProduct?.sunitprice}
                {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
              </span>
            </div>
            <p style={{ paddingLeft: "5px", paddingRight: "5px", color: "#929098", fontSize: "1em", marginBottom: 0 }}>{selectedProduct?.description}</p>
          </Col>

          <Row>
            <Col span={9} style={{ padding: 0 }} />
            <Col span={16} style={{ display: "flex", paddingLeft: "18.5vw", paddingTop: 0, paddingBottom: 0 }}>
              <Button
                icon={<MinusOutlined style={{ fontSize: "15px" }} />}
                style={{
                  width: "100px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: 6,
                  marginRight: "15px",
                  paddingLeft: 6,
                  borderRadius: "5px",
                  paddingTop: 0,
                }} // Set width, height, and center icon
                onClick={() => handleQty("minus")}
              />
              <p style={{ fontSize: "1.25em", color: "#0F0718", fontWeight: 500 }}>{selectedProduct?.weight || 1}</p>
              <Button
                icon={<PlusOutlined style={{ fontSize: "15px", color: "#fff" }} />}
                style={{
                  width: "100px",
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: 6,
                  marginLeft: "15px",
                  paddingLeft: 6,
                  backgroundColor: "#2F3856",
                  borderRadius: "5px",
                }} // Set width, height, and center icon
                onClick={() => handleQty("plus")}
              />
            </Col>
          </Row>
        </Row>
        <p style={{ fontSize: "1.25em", color: "#0F0718", fontWeight: 500, marginBottom: 5, paddingLeft: "5px" }}>Add On's</p>
        <Scrollbars autoHide className="addOnsModalScroll">
          <Row>
            <Col span={24} style={{ paddingRight: 3, paddingBottom: 10 }}>
              <Card bodyStyle={{ padding: 5 }} style={data.restaurantPos.content.addOnSelectionModal.mainCard}>
                {/* Required Add-Ons */}
                {addOnsList?.requiredList?.length > 0 && (
                  <div style={{ fontSize: "1vw" }}>
                    <Radio.Group
                      value={_.intersection(addOnsList?.requiredList || [], selectedAddons)?.[0] || {}}
                      onChange={(e) => handleAddOnValue(e, "addOnRadio")}
                      style={{ width: "100%" }}
                    >
                      {addOnsList?.requiredList?.map((item) => (
                        <Card key={item.mProductAddOnId} style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={24}>
                              <Radio value={item} style={{ fontSize: "1vw" }}>
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                              </Radio>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Radio.Group>
                  </div>
                )}

                {addOnsList?.optionsList?.length > 0 && (
                  <div style={{ background: "#F3F4F9" }}>
                    {Object.entries(_.groupBy(addOnsList?.optionsList, "mAddonGroup.name")).map(([key, value]) => (
                      <div key={key} style={{ background: "#F3F4F9" }}>
                        {value?.map((item) => (
                          <Card key={item.mProductAddOnId} style={data.restaurantPos.content.addOnSelectionModal.addOnCard} bodyStyle={{ padding: 10, color: "#0F0718" }}>
                            <Row>
                              <Col span={20}>
                                <Checkbox
                                  checked={selectedAddons.findIndex((itemAddOn) => itemAddOn.mProductAddOnId === item.mProductAddOnId) !== -1}
                                  value={item}
                                  onChange={(e) => handleAddOnValue(e, "addOnCheck")}
                                >
                                  <span style={data.restaurantPos.content.addOnSelectionModal.addOnTitle}>{item.name}</span>
                                </Checkbox>
                              </Col>
                              <Col span={4}>
                                <span style={data.restaurantPos.content.addOnSelectionModal.addOnPrice}>
                                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                  {item.price} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Scrollbars>

        <Row style={{}}>
          <Col span={24} style={{ fontSize: "1vw" }}>
            <Button style={data.restaurantPos.content.orderCart.checkoutBtn} className="addButton" onClick={handleAdd}>
              <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Add to Cart</span>
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default MenuLayout;
