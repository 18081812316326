import { currencyTypes } from "../../constants/taxCurrencies";

export const htmlPrint = (printReciptData, receiptType) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  const addedToCart = printReciptData.items;
  const taxKeyValue = [];
  const taxType = [];

  for (let i = 0; i < addedToCart.length; i += 1) {
    let taxRate = addedToCart[i].taxRate;
    if (taxType.indexOf(taxRate) !== -1) {
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      for (let j = 0; j < taxKeyValue.length; j += 1) {
        const keys = Object.keys(taxKeyValue[j]);
        if (keys[0].toString() === taxRate.toString()) {
          taxKeyValue[j][taxRate] = taxKeyValue[j][taxRate] + taxVal;
        }
      }
    } else {
      taxType.push(taxRate);
      let taxVal = currenciesList[0].isoCode === "INR" ? addedToCart[i].taxAmount / 2 : addedToCart[i].taxAmount;
      taxKeyValue.push({ [taxRate]: taxVal });
    }
  }

  let taxTypeCurrencyWise = currencyTypes.filter((item, index) => item.type === currenciesList[0].isoCode);

  let taxAmountBreakup = "";
  taxKeyValue.map((value, i) =>
    taxTypeCurrencyWise[0]?.taxType?.map(
      (item) =>
      (taxAmountBreakup += `<tr>
       <td>${item} ${item === "CGST" || item === "SGST" ? taxType[i] / 2 : taxType[i]}%</td>
       <td>${value[taxType[i]].toFixed(2)}</td>
     </tr>`)
    )
  );

  let printItems = "";
  printReciptData.items.map((data) => {
    if (data.weight !== 0) {
      return (printItems += `<tr class="left">
        <td colspan="2">${data.shortDescription === null ? data.name : data.shortDescription}</td>
        <td>${parseFloat(data.weight).toFixed(2)}</td>
        <td>${parseFloat(data.salePrice).toFixed(2)}</td>
     </tr>
     <tr class="left">
        <td colspan="${taxTypeCurrencyWise[0]?.taxType?.filter(item => item === "CGST" || item === "SGST")?.length === 0 ? 2 : 0}">${data.hsncode === null ? "-" : data.hsncode}</td>
        ${taxTypeCurrencyWise[0]?.taxType?.map(item => item === "CGST" || "SGST"
        ? `<td>${parseFloat(parseFloat(data.taxAmount) / 2).toFixed(2)}</td>`
        : `<td>${parseFloat(parseFloat(data.taxAmount)).toFixed(2)}</td>`
      )}
        <td>${parseFloat(data.nettotal).toFixed(2)}</td>
     </tr>
     `);
    }
  });

  let printPayments = "";
  printReciptData.payments.map((data) => {
    return (printPayments += `<tr>
           <td>${data.name}</td>
           <td>${parseFloat(data?.name === "Cash" ? data?.amount + printReciptData?.change : data?.amount).toFixed(2)}</td>
         </tr>`);
  });

  const customerName = printReciptData.customer.name === "Anonymous Customer" ? "" : printReciptData.customer.name;
  const customerMobile = printReciptData.customer.mobileNo === "9999999999" ? "" : printReciptData.customer.mobileNo;
  const fullAddress = tillData.tillAccess.csBunit.locations[0].fulladdress.replaceAll(", ", ",<br />");
  let couponCodeData = "";
  let billDetailsData = "";

  if (printReciptData.voucherCode !== null) {
    couponCodeData = `<p class="centered">Coupon Code</p><img src="https://api.qrserver.com/v1/create-qr-code/?data=${printReciptData.voucherCode}&amp;size=100x100" alt="CouponCode" title="" /><p>${printReciptData.voucherCode}</p>`;
  }

  const reciptQRBillDetails = `orderID%3A${printReciptData.sOrderID}%26%26billNo%3A${printReciptData.documentno}%26%26amount%3A${printReciptData.total}%26%26customer%3A${printReciptData.customer.name}%26%26`;
  billDetailsData = `<img src="https://api.qrserver.com/v1/create-qr-code/?data=${reciptQRBillDetails}&amp;size=100x100" alt="CouponCode" title="" />`;

  let printDataTemplate = tillData.tillAccess.cwrTill.printTemplate.htmlcode2;
  printDataTemplate = printDataTemplate.replaceAll("@@csBunitCustomerAddressFulladdress@@", `${fullAddress}`);
  printDataTemplate = printDataTemplate.replaceAll("@@csBunitCustomerAddressPhone@@", `${tillData.tillAccess.csBunit.locations[0].phone}`);
  printDataTemplate = printDataTemplate.replaceAll("@@receiptType@@", `${receiptType}`);
  printDataTemplate = printDataTemplate.replaceAll("@@username@@", `${localStorage.getItem("username")}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno}`);
  printDataTemplate = printDataTemplate.replaceAll("@@date@@", `${new Date().toLocaleString()}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataCustomerName@@", `${customerName}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataCustomerMobileNo@@", `${customerMobile}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataItems@@", `${printItems}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataItemsLength@@", `${parseFloat(printReciptData.items.length).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataTotalQty@@", `${parseFloat(printReciptData.totalQty).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@taxAmountBreakup@@", `${taxAmountBreakup}`);
  printDataTemplate = printDataTemplate.replaceAll("@@netAmount@@", `${(parseFloat(printReciptData.total) - parseFloat(printReciptData.tax)).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@taxAmount@@", `${parseFloat(printReciptData.tax).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@discountAmount@@", `${parseFloat(printReciptData.discount).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@roundOff@@", `${parseFloat(printReciptData.roundOff).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@total@@", `${parseFloat(printReciptData.total).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@printReciptDataPayments@@", `${printPayments}`);
  printDataTemplate = printDataTemplate.replaceAll("@@amountReceived@@", `${parseFloat(printReciptData.paid).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@cashChangeAmount@@", `${parseFloat(printReciptData.change).toFixed(2)}`);
  printDataTemplate = printDataTemplate.replaceAll("@@couponCode@@", `${couponCodeData}`);
  printDataTemplate = printDataTemplate.replaceAll("@@billDetails@@", `${billDetailsData}`);
  printDataTemplate = printDataTemplate.replaceAll("@@additionalBodyData@@", ``);

  setTimeout(() => {
    const printWindow = window.open("", "printWindow", "width=0,height=0");
    printWindow.document.write(printDataTemplate);

    const scriptData = document.createElement("script");
    scriptData.innerHTML = `
           Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
           .then(() => {
               window.print();
               // Close the window after the first print
               setTimeout(() => {
                   window.print(); // Second print
                   window.close(); // Close the window after the second print
               }, 1000); // Delay before the second print
           });
       `;

    printWindow.document.getElementsByTagName("body")[0].appendChild(scriptData);
  }, 500);

};