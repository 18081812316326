import { SyncData } from "../../Restaurant/Tables-Management/SyncData";
import { sendOrder } from "../../../../socket";
import db from "../../../../database";

export const CheckoutPercentageDiscount = async (
  product,
  pricingRule,
  setCart,
  cart,
  orderType,
  cartObj,
  discountValue,
  iscoupon,
  couponInput,
  uniqReferenceId,
  mPricingCouponId,
  mPricingRulesId,
  removeDiscounts
) => {
  // const addedToCart = cart.items;
  // const index = selectedRowKeys[0];
  // if (index >= 0 && !addedToCart[index].isReturn) {
  //   const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
  //   const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
  //   const mrp = parseFloat(sp) * addedToCart[index].weight;
  //   const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
  //   addedToCart[index].salePrice = sp;
  //   addedToCart[index].taxAmount = tax;
  //   addedToCart[index].nettotal = mrp;
  //   addedToCart[index].discount = discountAmt * addedToCart[index].weight;
  //   addedToCart[index].discountName = pricingRule.printedName;
  //   addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
  //   addedToCart[index].discountValue = manualDiscountInput;
  //   addedToCart[index].discountType = "PD";

  //   let totalTax = 0;
  //   let totalPrice = 0;
  //   let totalItemsQty = 0;
  //   let totalDiscounts = 0;
  //   for (let i = 0; i < addedToCart.length; i += 1) {
  //     totalPrice += addedToCart[i].nettotal;
  //     totalItemsQty += addedToCart[i].weight;
  //     totalTax += addedToCart[i].taxAmount;
  //     totalDiscounts += addedToCart[i].discount;
  //     addedToCart[i].key = i;
  //   }

  //   const roundOffValue = Math.round(totalPrice);
  //   const totalRoundOff = totalPrice - roundOffValue;
  //   totalPrice = roundOffValue;
  //   if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
  //     let obj;
  //     const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
  //     db.fbOrderData
  //       .where("cwrFbTableId")
  //       .equals(fbOrderData?.cwrFbTableId)
  //       .toArray()
  //       .then((ordersFetched) => {
  //         if (ordersFetched.length > 0) {
  //           ordersFetched.map(async (fbOrder) => {
  //             if (fbOrder.fbOrderStatus === "IP") {
  //               let orderLines = [];
  //               fbOrder.cart = {
  //                 ...cart,
  //                 items: [...addedToCart],
  //                 total: totalPrice,
  //                 tax: totalTax,
  //                 discount: totalDiscounts,
  //                 totalQty: totalItemsQty,
  //                 roundOff: totalRoundOff,
  //               };
  //               fbOrder.fbOrderSync = "N";
  //               fbOrder.lines = orderLines;
  //               addedToCart.map((obj) => {
  //                 orderLines.push(`{
  //                   fbOrderId: "${fbOrder.fbOrderId}"
  //                   fbOrderLineId: "${obj.fbOrderLineId}"
  //                   mPoductId: "${obj.productId}"
  //                   mBatchId: null
  //                   description: "good"
  //                   csUomId: "${obj.uom}"
  //                   csTaxId: "${obj.tax}"
  //                   discount:${obj.discount}
  //                   line: 1
  //                   qty: ${obj.weight}
  //                   unitPrice: ${obj.realPrice}
  //                   listPrice: 30
  //                   lineNet: 2.6
  //                   lineTax: ${obj.taxRate}
  //                   lineGross: 30
  //                   sOrderLineId: null
  //                   isOrdered: "Y"
  //                   meta:[]
  //                   }`);
  //               });
  //               await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
  //               obj = {
  //                 fbOrder: {
  //                   fbOrderId: fbOrder.fbOrderId,
  //                   order: fbOrder,
  //                 },
  //               };
  //               // sendOrder(obj);
  //             }
  //           });
  //         }
  //       });
  //     SyncData(fbOrderData, "upsertFbOrder");
  //   }

  //   let uniqueArray = cart?.manualDiscountData?.lineLevelDiscount || [];

  //   let duplicateIndex = uniqueArray.findIndex((item) => item.lineId === addedToCart[index].lineId);
  //   const itemData = {
  //     lineId: addedToCart[index].lineId,
  //     productId: addedToCart[index].productId,
  //     discountName: pricingRule.printedName,
  //     discountValue: manualDiscountInput,
  //   };
  //   if (duplicateIndex === -1) {
  //     uniqueArray.push(itemData);
  //   } else {
  //     uniqueArray[duplicateIndex] = itemData;
  //   }

  //   let manualDiscountData = {
  //     lineLevelDiscount: uniqueArray,
  //     ...cart.manualDiscountData,
  //   };

  //   let cartObj = {
  //     ...cart,
  //     items: [...addedToCart],
  //     total: totalPrice,
  //     tax: totalTax,
  //     discount: totalDiscounts,
  //     totalQty: totalItemsQty,
  //     roundOff: totalRoundOff,
  //     manualDiscountData: manualDiscountData,
  //   };
  //   setCart(cartObj);
  //   localStorage.setItem("cartObj", JSON.stringify(cartObj));
  // }

  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let modificationsMade = false; // Flag to indicate modifications

  // Iterate through each item in the cart
  cartObj.items.map((item) => {
    let excludingFlag = true;
    const matchingCategory = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === item.mProductCategoryId);
    if (
      (pricingRule.excludeProductCategories === "Y" && matchingCategory) ||
      (pricingRule.excludeProductCategories !== "Y" && !matchingCategory) ||
      (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find(
      (pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId
    );

    if (
      (pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) ||
      (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment) ||
      (pricingRule.excludeB2CSegment === "N" && pricingRule.mPricingB2CCustomerSegments.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
    if (
      (pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) ||
      (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer) ||
      (pricingRule.excludeB2CCustomers === "N" && pricingRule.mPricingB2CCustomers.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBusinessUnit = pricingRule.mPricingBUnits.find((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
    if (
      (pricingRule.excludeBusinessUnits === "Y" && matchingBusinessUnit) ||
      (pricingRule.excludeBusinessUnits !== "Y" && !matchingBusinessUnit) ||
      (pricingRule.excludeBusinessUnits === "N" && pricingRule.mPricingBUnits.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === item.productBrandId);
    if (
      (pricingRule.excludeBrands === "Y" && matchingBrand) ||
      (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
      (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId);
    if (
      (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
      (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
      (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
    ) {
      excludingFlag = false;
    }

    let pricingRuleFlag = true;

    // Apply additional logic for pricingRuleFlag based on your requirements

    if (item.discount <= 0 && item.mPricingRulesId !== null && item.mPricingRulesId !== undefined) {
      pricingRule.mPricingXProducts.forEach((res) => {
        if (res.mProductId === item.productId) {
          delete item.priority;
        }
      });
    }

    if (
      saleTypeValidation &&
      excludingFlag &&
      pricingRuleFlag &&
      (product.productId === item.productId || iscoupon || removeDiscounts) &&
      (item?.priority ? item?.priority < pricingRule.priority : true)
    ) {
      const offerIndex =
        pricingRule.mPricingXProducts.length > 0 && pricingRule.excludeProducts === "N"
          ? pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId)
          : pricingRule.mPricingXProducts.length > 0 && pricingRule.excludeProducts === "Y"
          ? pricingRule.mPricingXProducts.findIndex((op) => op.mProductId !== item.productId)
          : 0;

      if (offerIndex >= 0 && Math.abs(item.weight) >= pricingRule.minimumQty) {
        const discountAmt = ((discountValue / 100) * parseFloat(item.realPrice)).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision);
        const sp = parseFloat(item.realPrice) - discountAmt;
        const mrp = parseFloat(sp) * item.weight;
        const tax = mrp - mrp / (1 + item.taxRate / 100);
        item.salePrice = sp;
        item.taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        item.nettotal = parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        item.discount = parseFloat((discountAmt * item.weight).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        item.discountName = pricingRule.printedName;
        item.mPricingruleId = pricingRule.mPricingrulesId;
        item.nextRule = pricingRule.nextRule;
        item.iscoupon = iscoupon;
        item.couponInput = couponInput;
        item.referenceId = uniqReferenceId;
        item.mPricingCouponId = mPricingCouponId;
        item.priority = pricingRule.priority;
        modificationsMade = true;
        // Modifications made for the current item
      } else {
        const mrp = parseFloat(item.realPrice) * item.weight;
        const tax = mrp - mrp / (1 + item.taxRate / 100);
        item.taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        item.nettotal = parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        item.discount = 0;
        item.discountName = "";
        item.mPricingruleId = null;
        delete item.nextRule;
      }
    }
  });

  if (modificationsMade) {
    // Update the cart object with the adjusted items
    let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
    if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null && mPricingRulesId) {
      couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
    }

    let uniqueArray = cart?.manualDiscountData?.lineLevelDiscount || [];

    let duplicateIndex = uniqueArray.findIndex((item) => item.lineId === product.lineId);
    const itemData = {
      lineId: product.lineId,
      productId: product.productId,
      discountName: pricingRule.printedName,
      discountValue: discountValue,
      pricingRule: pricingRule.mPricingrulesId,
    };
    if (duplicateIndex === -1) {
      uniqueArray.push(itemData);
    } else {
      uniqueArray[duplicateIndex] = itemData;
    }

    let manualDiscountData = {
      lineLevelDiscount: uniqueArray,
      ...cart.manualDiscountData,
    };

    const adjustedCartObj = {
      ...cartObj,
      items: cartObj.items,
      iscoupon: iscoupon,
      couponInput: couponsData,
      manualDiscountData: manualDiscountData,
    };
    setCart(adjustedCartObj);
    localStorage.setItem("cartObj", JSON.stringify(adjustedCartObj));
    return adjustedCartObj;
  }
};
