import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Row, Col, Table, Drawer, Button, Input, Select, message, Modal } from "antd";
import { PlusOutlined, CloseOutlined, CloseSquareFilled } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import sideMenu from "../../../../assets/images/side-menu.svg";
import userIcon from "../../../../assets/images/userId.svg";
import Online from "../../../../assets/images/green.svg";
import Ofline from "../../../../assets/images/red.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import Close from "../../../../assets/images/close-x.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import DefaultProductImage from "../../../../assets/images/no-image.svg";
import CancelArrow from "../../../../assets/images/cancelArrow.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { getOAuthHeaders } from '../../../../constants/oAuthValidation';
import Axios from 'axios';
import BarCodeComponentForRetail from "../../../../lib/BarCodeComponentForRetail";
import data from "../../../../constants/retailPos.json";
import Keyboard from "react-simple-keyboard";
import db from "../../../../database";
import NotFoundImg from "../../../../assets/images/NotFoundImg.svg";

const ProductionOrder = (props) => {
  const {
    clearProductSearchResults,
    productSearchInput,
    keyboardRef,
    formattedDateTime,
    setEnableProduction,
    setTillLink
  } = props;
  const { Option } = Select;
  const { access_token } = getOAuthHeaders();
  const genericUrl = process.env.REACT_APP_genericUrl;
  const retailUrl = process.env.REACT_APP_serverUrl;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;
  const [chooseProduct, setChooseProduct] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [selectedKey, setSelectedKey] = useState(null);
  const [isNewInput, setIsNewInput] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductSalePrice, setSelectedProductSalePrice] = useState(0);
  const [chooseProductsData, setChooseProductsData] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [searchedProduct, setSearchedProduct] = useState("");
  const [chooseSearchedProduct, setChooseSearchedProduct] = useState("");
  const [searchedProductsData, setSearchedProductsData] = useState([]);
  const [searchedProductsFlag, setSearchedProductsFlag] = useState(false);
  const [searchedProductsDrawerData, setSearchedProductsDrawerData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [showRemarks, setShowRemarks] = useState(false);
  const [otherCharges, setOtherCharges] = useState(false);
  const [layoutName, setLayoutName] = useState('default');
  const [remarks, setRemarks] = useState("");
  const [showInputFields, setShowInputFields] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [productionTypeData, setProductionTypeData] = useState([]);
  const [productionPrefix, setProductionPrefix] = useState("");
  const [productionNextNo, setProductionNextNo] = useState("");
  const [productionName, setProductionName] = useState("");
  const [productionDocId, setProductionDocId] = useState("");
  const [otherChargesData, setOtherChargesData] = useState([]);
  const [tempOtherChargesData, setTempOtherChargesData] = useState([]);
  const inputRef = useRef(null);
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  const notFoundModal = () => {
    Modal.info({
      icon: null,
      okButtonProps: { style: { display: 'none' } },
      okText: "Go Back",
      content: (
        <div style={{
          fontSize: '18px',
          fontWeight: '500',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: 0,
          fontSize: "1vw"
        }}>
          <img src={NotFoundImg} alt="Not Found" style={{ width: '120px', height: '100px', marginBottom: '5px', marginTop: "10px" }} />
          <p style={{ margin: '5px 0', fontSize: '2em', fontWeight: '600', color: "#0F0718" }}>Product Not Found</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5" }} />
          <p style={{ color: "#0F0718", fontWeight: 400, fontSize: "1.25em", padding: '15px 15px 0 15px' }}>The scanned product is not in the system. Please check the barcode or enter the item manually.</p>
        </div>
      ),
      style: {
        textAlign: 'center',
        height: "50vh",
        width: "50%"
      },
      bodyStyle: {
        padding: '0',
      },
      closable: false,
      onOk: null,
    });
    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  };

  useEffect(() => {
    if (chooseProduct) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [chooseProduct]);

  useEffect(() => {
    db.docTypesData.toArray().then((docTypes) => {
      const requiredDocTypes = docTypes.filter(docType => docType?.doccategory === "BOM");
      setProductionTypeData(requiredDocTypes);
      setProductionDocId(requiredDocTypes[0]?.cs_doctype_id);
      setProductionName(requiredDocTypes[0]?.name);
      setProductionPrefix(requiredDocTypes[0]?.prefix);
      setProductionNextNo(requiredDocTypes[0]?.nextno);
    });
  }, []);

  useEffect(() => {
    const totalWeights = otherChargesData?.reduce((acc, item) => acc + (parseFloat(item.weight) || 0), 0) || 0;
    let totalSalePrice = selectedProductSalePrice + subtotal + totalWeights;
    setTotalSalePrice(totalSalePrice);
  }, [selectedProductSalePrice, subtotal, otherChargesData]);

  useEffect(() => {
    if (otherCharges) {
      setTempOtherChargesData([...otherChargesData]);
    }
  }, [otherCharges, otherChargesData]);

  const updateCartTotals = (updatedItems) => {
    let totalItemsCount = updatedItems.length;
    let totalQtyCount = updatedItems.reduce((acc, product) => acc + (product.weight || 0), 0);
    let subtotalAmount = updatedItems.reduce((acc, product) => acc + (product.total || 0), 0);
    setTotalItems(totalItemsCount);
    setTotalQty(totalQtyCount);
    setSubtotal(subtotalAmount);
  };

  const handleAmount = (value) => {
    if (value === 'x') {
      setSelectedQuantity((prev) => Math.floor(prev / 10));
    } else {
      if (isNewInput) {
        setSelectedQuantity(parseInt(value));
        setIsNewInput(false);
      } else {
        setSelectedQuantity((prev) => parseInt(prev + value));
      }
    }
  };

  const handleEnter = () => {
    if (selectedKey !== null) {
      const updatedItems = searchedProductsData?.map(item =>
        item.key === selectedKey ? { ...item, weight: selectedQuantity, total: selectedQuantity * item.sale_price } : item
      );
      updateCartTotals(updatedItems);
      setSearchedProductsData(updatedItems);
      setIsNewInput(true);
    };
  };

  const incrementQuantity = () => {
    setSelectedQuantity((prev) => prev + 1);
  };

  const decrementQuantity = () => {
    setSelectedQuantity((prev) => {
      const newValue = prev - 1;
      return newValue >= 0 ? newValue : 0;
    });
  };

  const handleProductSelect = (product) => () => {
    const currentDate = new Date();
    const formattedDate = currentDate.getDate().toString().padStart(2, '0') + '/' +
      (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      currentDate.getFullYear();
    setFormattedDate(formattedDate);
    setSelectedProductSalePrice(product?.sale_price !== null ? product?.sale_price : 0);
    setSelectedProduct(product);
    setChooseProduct(false);
  };

  const handleChooseProduct = async () => {
    const params = {
      limit: "50",
      offset: "0",
      searchKey: chooseSearchedProduct || ""
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      let chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      setChooseProductsData(chooseProductData);
      setChooseProduct(true);
    };
  };

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      handleReachBottom();
    };
  };

  const handleReachBottom = async () => {
    const params = {
      limit: "50",
      offset: chooseProductsData?.length.toString(),
      searchKey: chooseSearchedProduct || ""
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      const chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      setChooseProductsData(prevData => [...prevData, ...chooseProductData]);
    };
  };

  const fetchSearchedProductData = async (params) => {
    const stringifiedFields = JSON.stringify(params);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);
    const searchedProductQuery = {
      query: `mutation { executeAPIBuilder(apiBuilderId: "652f64627acdc753d7d58b34", params: ${stringRequest})}`
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`
    };
    const serachedProductResponse = await Axios.post(genericUrl, searchedProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (serachedProductResponse.status === 200) {
      const searchedProductData = JSON.parse(serachedProductResponse?.data?.data?.executeAPIBuilder);
      if (searchedProductData.length > 1) {
        setSearchedProductsFlag(true);
        setSearchedProductsDrawerData(searchedProductData);
      } else if (searchedProductData.length === 0) {
        notFoundModal();
      } else {
        setSearchedProductsData(prevData => {
          let modifiedSearchedProductData = searchedProductData.map((product) => ({
            ...product,
            weight: 1,
            total: product?.sale_price !== null ? product?.sale_price : 0,
            sale_price: product?.sale_price !== null ? product?.sale_price : 0,
          }));
          let concatData = [...prevData, ...modifiedSearchedProductData];
          const modifiedProductData = concatData.map((product, index) => ({
            ...product,
            key: index,
          }));
          updateCartTotals(modifiedProductData);
          return modifiedProductData;
        });
      };
      setSearchedProduct("");
      setSelectedRowKey(null);
      setShowInputFields(false);
    };
  };

  const barCodeChooseProduct = async (data) => {
    const params = {
      limit: "50",
      offset: "0",
      searchKey: data
    };
    const paramsString = JSON.stringify(params).replace(/"/g, '\\"');
    const chooseProductQuery = {
      query: `mutation { executeAPIBuilder ( apiBuilderId : "66f150288e3f1f618d89f45e", params: "${paramsString}" )}`
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`
    };
    const chooseProductResponse = await Axios.post(genericUrl, chooseProductQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (chooseProductResponse.status === 200) {
      let chooseProductData = JSON.parse(chooseProductResponse?.data?.data?.executeAPIBuilder);
      if (chooseProductData.length === 1) {
        const currentDate = new Date();
        const formattedDate = currentDate.getDate().toString().padStart(2, '0') + '/' +
          (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
          currentDate.getFullYear();
        setFormattedDate(formattedDate);
        setSelectedProductSalePrice(chooseProductData[0]?.sale_price !== null ? chooseProductData[0]?.sale_price : 0);
        setSelectedProduct(chooseProductData[0]);
        return false;
      } else if (chooseProductData.length === 0) {
        notFoundModal();
        setChooseProductsData(chooseProductData);
        return true;
      } else {
        setChooseProductsData(chooseProductData);
        return true;
      }
    };
  };

  const onBarcodeInput = (data, flag) => {
    const params = {
      searchKey: data,
      cs_bunit_id: userData.bunit_id
    };
    setChooseProduct(prevData => {
      if (prevData === true) {
        barCodeChooseProduct(data).then(result => {
          setChooseProduct(result);
        });
      } else {
        fetchSearchedProductData(params);
      };
      return prevData;
    });
  };

  const handleAddSearchedProduct = (product) => () => {
    setSearchedProductsData(prevData => {
      product['weight'] = 1;
      product['sale_price'] = product?.sale_price !== null ? product?.sale_price : 0;
      product['total'] = product?.sale_price !== null ? product?.sale_price : 0;
      let concatData = [...prevData, product];
      const modifiedProductData = concatData.map((product, index) => ({
        ...product,
        key: index
      }));
      updateCartTotals(modifiedProductData);
      return modifiedProductData;
    });
    setSearchedProductsFlag(false);
  };

  const handleDeleteSearchedProduct = (record) => {
    setSearchedProductsData(prevData => {
      const updatedData = prevData.filter(product => product.key !== record.key);
      const updatedWithNewKeys = updatedData.map((product, index) => ({
        ...product,
        key: index
      }));
      updateCartTotals(updatedWithNewKeys);
      return updatedWithNewKeys;
    });
    setSelectedRowKey(null);
    setShowInputFields(false);
  };

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  const handleProductionTypeData = (value) => {
    db.docTypesData.toArray().then((docTypes) => {
      const requiredDocType = docTypes.filter(docType => docType?.cs_doctype_id === value);
      setProductionNextNo(requiredDocType[0]?.nextno);
      setProductionName(requiredDocType[0]?.name);
      setProductionDocId(requiredDocType[0]?.cs_doctype_id);
      setProductionPrefix(requiredDocType[0]?.prefix);
    });
    const productionTypeSelector = document.getElementById("BOM_production_type");
    if (productionTypeSelector) {
      productionTypeSelector?.blur();
    };
  };

  const handleOtherChanges = async () => {
    if (otherChargesData.length === 0) {
      const otherChangesQuery = {
        query: `mutation { executeAPIBuilder(apiBuilderId:"66f408228e3f1f618d89f88b", params: "{}")}`
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${access_token}`
      };
      const otherChangesResponse = await Axios.post(genericUrl, otherChangesQuery, { headers: headers }, { async: true }, { crossDomain: true });
      if (otherChangesResponse.status === 200) {
        const otherChargesData = JSON.parse(otherChangesResponse?.data?.data?.executeAPIBuilder);
        const updatedOtherChangesData = otherChargesData.map(item => ({
          ...item,
          weight: ""
        }));
        setOtherChargesData(updatedOtherChangesData);
        setOtherCharges(true);
      };
    } else {
      setOtherCharges(true);
    };
  };

  const handleCancel = () => {
    setChooseProduct(false);
    setSelectedQuantity(0);
    setSelectedKey(null);
    setSelectedProduct(null);
    setSelectedProductSalePrice(0);
    setChooseProductsData([]);
    setFormattedDate("");
    setSearchedProduct("");
    setChooseSearchedProduct("");
    setSearchedProductsData([]);
    setSearchedProductsFlag(false);
    setSearchedProductsDrawerData([]);
    setSubtotal(0);
    setTotalItems(0);
    setTotalQty(0);
    setTotalSalePrice(0);
    setRemarks("");
    setOtherChargesData([]);
    setTempOtherChargesData([]);
  };

  const handleProceed = async () => {
    let inputProducts = [];
    searchedProductsData?.map(product => {
      let obj = {
        mProductId: product.m_product_id,
        csUomId: product.cs_uom_id,
        actualqty: product.weight,
        salePrice: product.sale_price
      };
      inputProducts.push(obj);
    });
    let indirectCost = [];
    otherChargesData?.map(charge => {
      if (charge.weight !== "") {
        let obj = {
          prIndirectcostId: charge.pr_indirectcost_id,
          value: parseInt(charge.weight),
          cost: parseInt(charge.weight)
        };
        indirectCost.push(obj);
      };
    });
    const proceedQuery = {
      query: `mutation{
          upsertBOMProduction(order : {
                  csBunitId: "${userData.bunit_id}",
                  csDoctypeId: "${productionDocId}",
                  documentno: "${productionPrefix}${productionNextNo}",
                  date: "${formattedDate.split('/').reverse().join('-')}",
                  mProductId: "${selectedProduct?.m_product_id}",
                  qtyproduction: 1,
                  description: "${remarks}"
                  salePrice: ${totalSalePrice}
                  inputProducts: ${JSON.stringify(inputProducts).replace(/"(\w+)":/g, '$1:')} 
                  indirectCost: ${JSON.stringify(indirectCost).replace(/"(\w+)":/g, '$1:')} 
              }){
              status
              message
              recordId
          }
      }`
    };
    const hardcodedDigit = "3";
    const productSkuValue = selectedProduct?.value.toString().padStart(6, "0").slice(0, 6);
    const totalSalePriceValue = (Math.floor(totalSalePrice * 100)).toString().padStart(6, '0').slice(0, 6);
    const finalBarCodeNumber = hardcodedDigit + productSkuValue + totalSalePriceValue;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [day, month, year] = formattedDate.split("/");
    const shortYear = year.slice(-2);
    const newFormattedDate = `${day}-${months[parseInt(month) - 1]}-${shortYear}`;
    const json = `{
      "printer_type": "thermal",
      "printer_id": "POS123",
      "document": {
        "type": "barcode_label",
        "content": [
          {
            "type": "barcode",
            "value": "${finalBarCodeNumber}",
            "format": "CODE128",
            "align": "center",
            "height": 100,
            "width": 2
          },
          {
            "type": "divider",
            "character": " ",
            "length": 1
          },
          {
            "type": "text",
            "value": "${selectedProduct?.value.toString().padStart(6, "0").slice(0, 6)}/${selectedProduct?.uom}/${selectedProduct?.name}",
            "align": "left",
            "font": "bold",
            "size": "large"
          },
          {
            "type": "text",
            "value": "Price:",
            "align": "left",
            "font": "normal",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "${currenciesList[0]?.currSymbol}${totalSalePrice}",
            "align": "right",
            "font": "bold",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "Lot:",
            "align": "left",
            "font": "normal",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "${productionPrefix}${productionNextNo}",
            "align": "right",
            "font": "bold",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "Date:",
            "align": "left",
            "font": "normal",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "${newFormattedDate}",
            "align": "right",
            "font": "normal",
            "size": "medium"
          }
        ]
      },
      "settings": {
        "copies": 1,
        "cut_after_print": true,
        "paper_width": "80mm",
        "density": "high"
      }
    }`;
    Axios.post(`${printerURL}printer`, json, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
    })
      .then(() => {
        console.log("BOM Print success!");
      })
      .catch((response) => {
        console.log("BOM Print failed!", response);
      });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${access_token}`,
    };
    try {
      const proceedResponse = await Axios.post(retailUrl, proceedQuery, { headers: headers }, { async: true }, { crossDomain: true });
      if (proceedResponse.status === 200) {
        db.docTypesData.where("cs_doctype_id").equals(productionDocId).modify({ nextno: productionNextNo + 1 });
        setChooseProduct(false);
        setSelectedQuantity(0);
        setSelectedKey(null);
        setSelectedProduct(null);
        setSelectedProductSalePrice(0);
        setChooseProductsData([]);
        setFormattedDate("");
        setSearchedProduct("");
        setChooseSearchedProduct("");
        setSearchedProductsData([]);
        setSearchedProductsFlag(false);
        setSearchedProductsDrawerData([]);
        setSubtotal(0);
        setTotalItems(0);
        setTotalQty(0);
        setTotalSalePrice(0);
        setRemarks("");
        setOtherChargesData([]);
        setTempOtherChargesData([]);
        setProductionNextNo(prevNum => prevNum + 1);
        message.success(`${proceedResponse?.data?.data?.upsertBOMProduction?.recordId} ${proceedResponse?.data?.data?.upsertBOMProduction?.message}`);
      } else {
        message.error("Something is wrong");
      }
    } catch (error) {
      message.error("Something is wrong with proceed query");
      console.error("Proceed query failed", error);
    };
  };

  return (
    <>
      <BarCodeComponentForRetail onScan={onBarcodeInput} setProductSearchInput={setSearchedProduct} productSearchInput={searchedProduct} />
      <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
        <Row
          style={{
            padding: "0.5% 0",
            height: "9vh",
            alignItems: "center",
            fontSize: "1vw",
          }}
        >
          <Col
            onClick={() => {
              setTillLink(true);
            }}
            style={{ cursor: "pointer" }}
            span={1}
          >
            <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
          </Col>
          <Col span={3}>
            <img src={POSLogo} onClick={() => setEnableProduction(false)} style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }} />
          </Col>
          <Col span={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "1.5em",
                color: "#0F0718",
                marginLeft: "5vw",
                borderRadius: "2px",
                marginTop: "0.45rem"
              }}
            >
              Production
            </span>
          </Col>
          <Col
            span={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: "#0F0718",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "1em",
                paddingLeft: "1vw",
              }}
            >
              Production Order {productionPrefix}{productionNextNo}
            </span>
          </Col>

          <Col span={2} />

          <Col
            span={5}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "1em",
                color: "#929098",
                marginLeft: "3vw",
                marginRight: "4vw"
              }}
            >
              {" "}
              {formattedDateTime}
            </span>
          </Col>

          <Col span={1} />

          <Col
            span={3}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0.5vw",
                border: "1px solid #3C424B",
                borderRadius: "50%",
              }}
            >
              <img src={userIcon} style={{ height: "2.7vh" }} />
            </div>
            <div
              style={{
                marginLeft: "1vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "1em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                {userData.user}
              </span>
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "0.8em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
              </span>
            </div>
            <div style={{ marginLeft: "3%" }}>
              <img
                src={Online}
                style={{
                  display: !navigator.onLine && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
              <img
                src={Ofline}
                style={{
                  display: navigator.onLine && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
            </div>
          </Col>
        </Row>
        <div style={{ display: "flex", height: "91vh" }}>
          <div style={{ width: "63.9%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "0 0 0 1.7%",
              }}
            >
              <div style={{ width: "98.3%" }}>
                <Input
                  placeholder="Search for products by code/name"
                  id="sm-product-search"
                  className="productSearch-sm"
                  style={{
                    borderRadius: "7px",
                    padding: "0.8vh 0.8vw",
                    borderColor: "#fff",
                  }}
                  suffix={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {productSearchInput.length > 0 ? (
                        <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
                      ) : null}
                    </div>
                  }
                  prefix={
                    <img
                      src={SearchIcon}
                      alt=""
                    />
                  }
                  value={searchedProduct}
                  onChange={(ev) => {
                    setSearchedProduct(ev.target.value);
                    localStorage.setItem("productName", ev.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ padding: "1.5%" }}>
              <Table
                className="sm-cart-table-pos"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  borderRadius: "7px",
                  padding: "0px 7px",
                  height: "56.5vh",
                  background: "#fff",
                }}
                pagination={false}
                sticky
                id="sm-cart-table"
                onRow={(record) => ({
                  onClick: () => {
                    if (selectedRowKey === record.key) {
                      if (showInputFields) {
                      } else {
                        setShowInputFields(true);
                      }
                    } else {
                      setSelectedQuantity(record.weight);
                      setSelectedKey(record.key);
                      setIsNewInput(true);
                      setShowInputFields(true);
                      setSelectedRowKey(record.key);
                    }
                  },
                })}
                rowClassName={(record) => (selectedRowKey === record.key ? "tableSelected" : "tableNonSelected")}
                columns={[
                  {
                    title: "Item Name",
                    dataIndex: "product_name",
                    key: "product_name",
                    width: "28%",

                  },
                  {
                    title: "BOM Qty",
                    dataIndex: "weight",
                    key: "weight",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Qty On Hand",
                    dataIndex: "stock",
                    key: "stock",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Sale Price",
                    dataIndex: "sale_price",
                    key: "sale_price",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Total",
                    dataIndex: "total",
                    key: "total",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "",
                    dataIndex: "",
                    key: "",
                    width: "8%",
                    align: "center",
                    render: (text, record) => {
                      return (
                        <img src={DeleteProduct} style={{ cursor: "pointer", height: "17px", width: "17px", }} onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteSearchedProduct(record);
                        }}
                        />
                      )
                    }
                  }
                ]}
                dataSource={searchedProductsData}
              />
            </div>
            <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
              <div
                style={{
                  background: "#fff",
                  height: "23.5vh",
                  width: "60%",
                  marginRight: "1%",
                  border: "1px solid #fff",
                  borderRadius: "7px",
                  padding: "0.5vh",
                  fontSize: "1vw",
                }}
              >
                <Row style={{ height: "4.5vh", fontSize: "1vw" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      textAlign: "left",
                      fontWeight: "500",
                      color: "#0F0718",
                      marginTop: "2vh"
                    }}
                  >
                    <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}>Subtotal:</p>
                  </Col>
                  <Col span={12} style={{ display: "flex", alignItems: "center", paddingRight: "0.8vw", textAlign: "right", justifyContent: "end", color: "#0F0718", fontSize: "1.2em", fontFamily: "Inter", fontWeight: 500 }}>
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                      {subtotal}
                      <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                    </p>
                  </Col>
                </Row>
                <Row style={{ height: "4.5vh", marginTop: "4vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}>Total Items:</p>
                  </Col>
                  <Col span={12} style={{ display: "flex", paddingRight: "0.8vw", textAlign: "right", justifyContent: "end", color: "#0F0718", fontSize: "1.2em", fontFamily: "Inter", fontWeight: 500 }}>
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {totalItems}
                    </p>
                    <span style={{ marginLeft: "2px" }}></span>
                  </Col>
                </Row>
                <Row style={{ height: "4.5vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                      marginTop: "2vh"
                    }}
                  >
                    <p style={{ display: "flex", alignItems: "center", color: "#0F0718", fontFamily: "Inter", fontWeight: 500, fontSize: "1.2em" }}> Total Qty:</p>
                  </Col>
                  <Col span={12} style={{ display: "flex", alignItems: "center", paddingRight: "0.8vw", textAlign: "right", justifyContent: "end", color: "#0F0718", fontSize: "1.2em", fontFamily: "Inter", fontWeight: 500 }}>
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {totalQty}
                    </p>
                  </Col>
                </Row>
              </div>
              <div style={{ height: "23.7vh", width: "40%", fontSize: "1vw" }} onClick={() => {
                setSelectedRowKey(null);
                setShowInputFields(false);
              }}>
                <div
                  id="sm-cart-total"
                  style={{
                    height: "23.5vh",
                    position: "relative",
                    background: "#fff",
                    borderRadius: "7px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1vw"
                  }}
                >
                  <p
                    style={{
                      padding: "0.7vw 0 0 1vw",
                      color: "#0F0718",
                      fontSize: "1.3em",
                      fontWeight: "500",
                      height: "4.5vh",
                      paddingRight: "17vw"
                    }}
                  >
                    Sale Price
                  </p>
                  <p
                    style={{
                      color: "#0F0718",
                      fontSize: "2.5em",
                      fontWeight: 600,
                      height: "14.4vh",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    {totalSalePrice}
                    <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "35.6%",
              padding: "0% 0% 0 0.4%",
              borderRadius: "7px",
              paddingBottom: "0",
              height: "89.5vh",
              marginRight: "1%",
              backgroundColor: "#fff",
              position: "relative",
              fontSize: "1vw"
            }}
          >
            {selectedProduct !== null ? (
              <div style={{ position: "relative" }}>
                <Row>
                  <Col span={6}>
                    <img src={selectedProduct?.imageurl ? selectedProduct?.imageurl : DefaultProductImage}
                      style={{
                        width: "100%",
                        height: "11vh",
                        borderRadius: "5px",
                        marginTop: "4vh",
                        marginLeft: "1.5vw"
                      }}
                    />
                  </Col>
                  <Col span={3} />
                  <Col span={15}
                    style={{ marginTop: "4vh" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", }}>
                      <span style={{ fontSize: "1.2em", fontWeight: 500, color: "#0F0718", textOverflow: "ellipsis", maxWidth: "100%" }}>
                        {selectedProduct?.name}
                      </span>
                      <span style={{ fontSize: "1.1em", fontWeight: 400, color: "#0F0718" }}>
                        Qty 1
                      </span>
                    </div>
                  </Col>
                </Row>
                <Scrollbars style={{ height: "57vh", fontSize: "1vw" }}>
                  <Row style={{ display: "flex", alignItems: "center", marginTop: "3vh" }}>
                    <Col span={8} style={{ marginLeft: "1.5vw", }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>
                        Production Type
                      </p>
                    </Col>
                    <Col span={1} />
                    <Col span={13} >
                      <Select
                        value={productionName}
                        id="BOM_production_type"
                        style={{ width: "96%", border: "1px solid #92909880", color: "#0F0718", fontSize: "1.1em" }}
                        onChange={(value) => handleProductionTypeData(value)}
                      >
                        {productionTypeData?.map((productionType, index) => {
                          return (
                            <Option key={index} value={productionType?.cs_doctype_id}>
                              {productionType?.name}
                            </Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                    <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center" }}>
                      <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>
                        Production Date
                      </p>
                    </Col>
                    <Col span={1} />
                    <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1em", margin: 0 }}>{formattedDate}</p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                    <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center", height: "100%" }}                    >
                      <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718", margin: 0 }}>Other Charges</p>
                    </Col>
                    <Col span={1} />
                    <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                      <PlusOutlined style={{ cursor: 'pointer' }} onClick={handleOtherChanges} />
                    </Col>
                  </Row>
                  {otherChargesData?.some(item => item.weight) && (
                    otherChargesData.map((item, index) => (
                      item?.weight && (
                        <Row key={index} style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                          <Col span={8} style={{ marginLeft: "1.5vw", display: "flex", alignItems: "center" }}>
                            <p style={{ fontWeight: 400, fontSize: "1em", color: "#0F0718", margin: 0 }}>
                              {item?.name}
                            </p>
                          </Col>
                          <Col span={1} />
                          <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ fontWeight: 500, color: "#0F0718", fontSize: "1em", margin: 0 }}>
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              {item?.weight}
                              <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                            </p>
                          </Col>
                        </Row>
                      )
                    ))
                  )}
                  <Row style={{ marginTop: "3vh", display: "flex", alignItems: "center" }}>
                    <Col span={8} style={{ marginLeft: "1.5vw", }}>
                      <p style={{ fontWeight: 400, fontWeight: "1em", color: "#0F0718" }}>Remarks</p>
                    </Col>
                    <Col span={1} />
                    <Col span={13}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <TextArea
                        style={{ width: "18vw", resize: "none", border: "1px solid #92909880", color: "#0F0718", fontSize: "1em", fontWeight: 500 }}
                        value={remarks}
                        onFocus={() => setShowRemarks(true)}
                      >
                      </TextArea>
                    </Col>
                  </Row>
                </Scrollbars>
                {showInputFields ? (
                  <div
                    style={{
                      position: "absolute",
                      top: showInputFields ? "58%" : "100%",
                      left: "0",
                      width: "100%",
                      height: "auto",
                      background: "rgba(255, 255, 255, 0.95)",
                      zIndex: 1000,
                      transition: "top 0.5s ease",
                    }}
                  >
                    <CloseOutlined
                      style={{
                        position: "absolute",
                        marginTop: "1rem",
                        right: "10px",
                        cursor: "pointer",
                        fontSize: "15px",
                        color: "#2F3856"
                      }}
                      onClick={() => {
                        setSelectedRowKey(null);
                        setShowInputFields(false);
                      }}
                    />
                    <Row style={{ margin: "0 0 0 0 " }}>
                      <Input
                        className="productSearch sm-payment-search totalInput"
                        style={{
                          borderRadius: "0.5vw",
                          height: "6.5vh",
                          margin: "8% 1% 1% 0%",
                          borderRadius: "5px",
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "2.5em",
                          borderColor: "#92909880",
                          color: "#2F3856"
                        }}
                        id="sm-total-amount-input"
                        placeholder="Enter Amount"
                        value={selectedQuantity}
                      />
                    </Row>
                    <Row style={{ padding: "0 1% 0 0", marginTop: "1vh" }} gutter={[8, 6]}>
                      <Col span={6}>
                        <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleAmount("1")}>
                          1
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleAmount("2")}>
                          2
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleAmount("3")}>
                          3
                        </button>
                      </Col>
                      <Col span={6}>
                        <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleAmount("x")}>
                          <img
                            src={CancelArrow}
                            alt="back space"
                            style={{ width: "20px", height: "15px" }}
                          />
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleAmount("4")}>
                          4
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleAmount("5")}>
                          5
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleAmount("6")}>
                          6
                        </button>
                      </Col>
                      <Col span={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            id="sm-amount-addQuantity"
                            className="amt-dial-btn-market-qty"
                            onClick={decrementQuantity}
                          >
                            -
                          </button>
                          <button
                            id="sm-amount-addQuantity"
                            className="amt-dial-btn-market-qty"
                            onClick={incrementQuantity}
                          >
                            +
                          </button>
                        </div>
                      </Col>
                      <Col span={18}>
                        <Row gutter={[8, 6]}>
                          <Col span={8}>
                            <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleAmount("7")}>
                              7
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleAmount("8")}>
                              8
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmount("9")}>
                              9
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleAmount("0")}>
                              0
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleAmount("00")}>
                              00
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button." className="amt-dial-btn-market" onClick={() => handleAmount(".")}>
                              .
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <button
                          id="sm-amount-buttonEnter"
                          className="amt-dial-btn-market2"
                          onClick={handleEnter}
                        >
                          Enter
                        </button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div style={{ paddingTop: "5vh", display: "flex", alignItems: "center", width: "97%", position: "absolute", marginTop: "2vh", fontSize: "1vw" }}>
                    <Button
                      style={{
                        color: "rgb(47, 56, 86)",
                        width: "60%",
                        fontWeight: 500,
                        borderRadius: "7px",
                        fontSize: "1.4em",
                        height: "8vh",
                        marginLeft: "0.7vw",
                        border: "1px solid #92909880"
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        color: "#fff",
                        width: "90%",
                        fontWeight: 500,
                        borderRadius: "7px",
                        background: "#2F3856",
                        fontSize: "1.4em",
                        height: "8vh",
                        marginLeft: "0.7vw",
                      }}
                      onClick={handleProceed}
                    >
                      Proceed
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ paddingTop: "2.5vh", display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    color: "#fff",
                    width: "95%",
                    fontWeight: 500,
                    borderRadius: "7px",
                    background: "#2F3856",
                    fontSize: "1.4em",
                    height: "14vh",
                    marginLeft: "0.7vw",
                  }}
                  onClick={handleChooseProduct}
                >
                  Choose a Product
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Drawer
        closable={false}
        placement="bottom"
        visible={chooseProduct}
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => setChooseProduct(false)}
        height="85vh"
      >
        <div style={{ position: "relative", padding: "1vh 1vw" }}>
          <img
            src={Close}
            onClick={() => setChooseProduct(false)}
            style={{
              cursor: "pointer",
              width: "1.5vw",
              position: "absolute",
              top: "2vh",
              right: "0.2vw",
            }}
            alt="Close"
          />
        </div>
        <Row gutter={[8, 0]}
          style={{
            marginTop: "2vh",
            borderRadius: "10px",
            paddingLeft: "1.5vw",
            fontSize: "1vw",
            display: "flex,",
            justifyContent: "center"
          }}
        >
          <Col span={22}>
            <p style={{ fontSize: "1.5em", fontWeight: 500, fontFamily: "Inter" }} >Trays ({chooseProductsData?.length})</p>
            <Input
              type="text"
              ref={inputRef}
              placeholder="Search for products by code/name"
              id="giftCardSearchInput"
              style={{
                width: "97%",
                padding: "5px",
                borderRadius: "5px",
                fontSize: "1vw",
                color: "#0F0718",
                height: "6vh",
                marginLeft: "0.105rem"
              }}
              prefix={
                <img
                  src={SearchIcon}
                  alt=""
                  style={{ height: "2.7vh", marginLeft: "0.3rem" }}
                />
              }
              value={chooseSearchedProduct}
              onChange={(ev) => {
                setChooseSearchedProduct(ev.target.value);
                localStorage.setItem("productName", ev.target.value);
              }}
            />
          </Col>
        </Row>
        <Scrollbars
          className="productionScroll"
          onScrollFrame={handleScroll}>
          <Row
            style={{
              borderRadius: "10px",
              fontSize: "1vw",
              display: "flex,",
              justifyContent: "center"
            }}
          >
            {chooseProductsData?.map((product) => {
              return (
                <Col
                  span={4}
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0rem 0.5rem",
                    flex: "0 0 100%",
                    marginTop: "2vh",
                    marginRight: "1vw",
                    cursor: 'pointer'
                  }}
                  onClick={handleProductSelect(product)}
                >
                  <img src={product?.imageurl !== null ? product?.imageurl : DefaultProductImage} alt="" style={{ margin: "0.7rem 0", height: "6rem" }} />
                  <span style={{ textAlign: "center", margin: "0.7rem 0" }}>
                    <p style={{ margin: 0, fontWeight: 500, fontSize: "1em", color: "#0F0718" }}>{product?.name}</p>
                    <span style={{ fontSize: "0.8em", fontWeight: 500, color: "#0F0718" }}></span>
                  </span>
                </Col>
              )
            })}
          </Row>
        </Scrollbars>
      </Drawer>
      <Drawer
        closable={false}
        placement="bottom"
        visible={searchedProductsFlag}
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => setSearchedProductsFlag(false)}
        height="85vh"
        title={
          <div style={{ position: "relative", padding: "1vh 1vw" }}>
            <img
              src={Close}
              style={{
                width: "1.5vw",
                position: "absolute",
                top: "2vh",
                right: "0.2vw",
                cursor: "pointer"
              }}
              onClick={() => setSearchedProductsFlag(false)}
              alt="Close"
            />
          </div>
        }
      >
        <Scrollbars className="productionScroll">
          {searchedProductsDrawerData?.map(product => {
            return (
              <Row gutter={[15, 11]} style={{ width: "98%", backgroundColor: "#fff", marginTop: "2.5vh", height: "13vh", marginLeft: "1vw", borderRadius: "10px" }}>
                <Col span={4}>
                  <img
                    style={{
                      width: "100%",
                      height: "11vh",
                      objectFit: "contain",
                      borderRadius: "5px",
                      marginTop: "1vh",
                      display: "flex",
                      alignItems: "center",
                    }}
                    src={product.imageurl ? product.imageurl : DefaultProductImage}
                    alt=""
                  />
                </Col>
                <Col span={16}>
                  <div style={{ maxWidth: "100%", overflow: "hidden", paddingTop: "1vh" }}>
                    <label style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: "nowrap", textOverflow: "ellipsis" }} className="productName">
                    </label>
                  </div>
                  <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                    {product.product_name}
                    <span style={{ display: "block" }}>
                      {product?.sale_price ? product?.sale_price : 0}
                    </span>
                  </p>
                </Col>
                <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <span className="productDescription-kiosk">
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}{product?.sale_price ? product?.sale_price : 0}<span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> / {product?.uomname}
                  </span>
                  <span
                    style={{ fontSize: "0.8em", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 2.5vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}
                    onClick={handleAddSearchedProduct(product)}
                  >
                    ADD
                  </span>
                </Col>
                <span tabIndex={0}></span>
              </Row>
            )
          })}
        </Scrollbars>
      </Drawer>
      <Drawer
        placement="bottom"
        visible={showRemarks}
        height="53vh"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              Add Your Remarks
            </p>
            <img
              src={Close}
              onClick={() => {
                setShowRemarks(false)
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingTop: 0 }}
        closable={true}
        onClose={() => {
          setShowRemarks(false)
        }}
        footer={null}
      >
        <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
          <Col span={11}>
            <TextArea
              id="notes-input"
              style={{ resize: "none" }}
              maxLength={200}
              rows={11}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "0.8em",
                color: "#929098",
                fontWeight: 500,
              }}
            >
              Max Characters : 200
            </span>
          </Col>
          <Col span={12} offset={1}>
            <Keyboard
              theme={"hg-theme-default aprvl"}
              keyboardRef={(r) => (keyboardRef.current = r)}
              onKeyPress={(button) => {
                if (button === "{shift}" || button === "{lock}") {
                  handleShift();
                  return;
                }
                if (button === "{number}") {
                  setLayoutName("number");
                  return;
                }
                if (button === "{numbers}") {
                  setLayoutName("numbers");
                  return;
                }
                if (button === "{small}") {
                  setLayoutName("shift");
                  return;
                }
                if (button === "{caps}") {
                  setLayoutName("default");
                  return;
                }
                if (button === "{enter}") {
                  setRemarks(remarks);
                  setShowRemarks(false)
                  return;
                }
                if (button === "{space}") {
                  setRemarks((prev) => prev + " ");
                } else if (button === "{backspace}") {
                  setRemarks((prev) => prev.slice(0, -1));
                } else {
                  setRemarks((prev) => prev + button.replace(/[{}]/g, ''));
                }
              }}
              layoutName={layoutName}
              display={{
                "{abc}": "ABC",
                "{smileys}": "\uD83D\uDE03",
                "{back}": "Enter",
                "{numbers}": "123",
                "{number}": "123",
                "{bksp}": "backspace",
                "{backspace}": "⌫",
                "{shift}": "⇧",
                "{tab}": "tab",
                "{lock}": "lock",
                "{enter}": "Enter",
                "{space}": "Space",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
              }}
            />
          </Col>
        </Row>
      </Drawer>
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Additional Charges</p>
            <img
              src={Close}
              onClick={() => {
                setOtherCharges(false);
                setTempOtherChargesData([...otherChargesData]);
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={otherCharges}
        className="filter"
        bodyStyle={{ padding: "0 4vw" }}
        onClose={() => {
          setOtherCharges(false);
          setTempOtherChargesData([...otherChargesData]);
        }}
        height="90vh"
      >
        <Table
          className="sm-cart-table-pos"
          style={{
            width: "100%",
            overflowY: "auto",
            border: "none",
            borderRadius: "7px",
            padding: "0px 7px",
            height: "67vh",
            background: "#fff",
            marginTop: "2vh"
          }}
          pagination={false}
          sticky
          dataSource={tempOtherChargesData}
          columns={[
            {
              title: "Charge Name",
              dataIndex: "name",
              key: "name",
              width: "32%",
            },
            {
              title: "UOM",
              dataIndex: "uom_name",
              key: "uom_name",
              width: "12%",
              align: "center",
            },
            {
              title: "Order Qty",
              dataIndex: "orderQty",
              key: "orderQty",
              width: "15%",
              align: "right",
            },
            {
              title: "Available Qty",
              dataIndex: "availableQty",
              key: "availableQty",
              width: "15%",
              align: "right",
            },
            {
              title: "Charge Amount",
              dataIndex: "weight",
              key: "weight",
              width: "22%",
              align: "center",
              render: (text, record) => {
                return (
                  <Input
                    style={{ width: "10vw", textAlign: "right", border: "1px solid #92909880" }}
                    value={record?.weight}
                    onChange={(ev) => {
                      let updatedWeight = ev.target.value;
                      let updatedTempData = tempOtherChargesData.map(item =>
                        item.name === record.name ? { ...item, weight: updatedWeight } : item
                      );
                      setTempOtherChargesData(updatedTempData);
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#2F3856",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
            onClick={() => {
              setOtherCharges(false);
              setTempOtherChargesData([...otherChargesData]);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={() => {
              setOtherChargesData(tempOtherChargesData);
              setOtherCharges(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default ProductionOrder;